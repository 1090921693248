import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["overlay", "content", "modal"];

    connect() {
        console.log("Modal controller connected");
        document.body.classList.add("overflow-hidden");
    }

    hideModal() {
        this.overlayTarget.classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
        this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
        this.element.remove()
    }

    // hide modal on successful form submission
    // action: "turbo:submit-end->turbo-modal#submitEnd"
    submitEnd(e) {
        if (e.detail.success) {
            this.hideModal()
        }
    }
    // hide modal when clicking ESC
    // action: "keyup@window->turbo-modal#closeWithKeyboard"
    closeWithKeyboard(e) {
        if (e.code == "Escape") {
            this.hideModal()
        }
    }
    // hide modal when clicking outside of modal
    // action: "click@window->turbo-modal#closeBackground"
    closeBackground(e) {
        if (e && this.modalTarget.contains(e.target)) {
            return
        }
        this.hideModal()
    }
}